// Polyfills
import 'Utils/_closest.polyfill.js';
import 'Utils/_matches.polyfill.js';

// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Components
import { disablePageScroll, enablePageScroll, addFillGapTarget, addFillGapSelector, setFillGapMethod } from 'scroll-lock';
import Swiper, { Pagination, Navigation, Autoplay, FreeMode, EffectFade } from 'swiper';


document.addEventListener('DOMContentLoaded', function() {
	let mm = gsap.matchMedia();
	
	var body = document.body;
	var testSectionFirst = document.querySelector('.section.--first');

	// APPEAR ANIMATION
	if(testSectionFirst) {
		gsap.to('.section.--first', {
			//ease: "none",
			opacity:1,
		});
	}
	
	const sectionsFadeAppear = document.querySelectorAll(".section.--fade:not(.--multi)");
	sectionsFadeAppear.forEach(sectionFadeAppear => {		
		gsap.to(sectionFadeAppear, {
			//ease: "none",
			opacity:1,
			scrollTrigger: {
				trigger: sectionFadeAppear,
				start: "top 78%",
				//markers: true,
			}, 
		});
	})

	const sectionsFadeMultiAppear = document.querySelectorAll(".section.--fade.--multi");
	sectionsFadeMultiAppear.forEach(sectionFadeMultiAppear => {		
		const sectionsFadeMultiStaggerAppear = sectionFadeMultiAppear.querySelectorAll(".stagger-item");

		gsap.to(sectionsFadeMultiStaggerAppear, {
			//ease: "none",
			opacity:1,
			stagger: 0.1,
			scrollTrigger: {
				trigger: sectionFadeMultiAppear,
				start: "top 78%",
				//markers: true,
			}, 
		});
	})

	const sectionsTranslateAppear = document.querySelectorAll(".section.--translate");
	sectionsTranslateAppear.forEach(sectionTranslateAppear => {		
		const sectionsTranslateStaggerAppear = sectionTranslateAppear.querySelectorAll(".stagger-item");

		gsap.to(sectionsTranslateStaggerAppear, {
			//ease: "none",
			opacity:1,
			y:0,
			stagger: 0.1,
			scrollTrigger: {
				trigger: sectionTranslateAppear,
				start: "top 78%",
				//markers: true,
			}, 
		});
	})
	
	
	// POPUP CONTACT
	const closePopupBtn = document.getElementById("closePopupBtn");
	const overlayPopup = document.getElementById("popupOverlayJS");
	
	const buttonsContact = document.querySelectorAll('.btnContactJS');
	var $scrollableElement = document.querySelector('.my-scrollable-element');

	var method = 'margin'; //Available methods: none, padding, margin, width, max-width
	setFillGapMethod(method);
	
	buttonsContact.forEach(btnContact => {
		btnContact.addEventListener('click', function() {
			body.classList.add('popup-open');
			disablePageScroll($scrollableElement);
			document.querySelector('.no-js').style.overflow = "hidden";
		});
	});

	closePopupBtn.addEventListener('click', function() {
		body.classList.remove('popup-open');
		enablePageScroll($scrollableElement);
		document.querySelector('.no-js').style.overflow = "auto";
	});

	overlayPopup.addEventListener('click', function() {
		body.classList.remove('popup-open');
		enablePageScroll($scrollableElement);
		document.querySelector('.no-js').style.overflow = "auto";
	});

	document.onkeydown = function(evt) {
		evt = evt || window.event;
		var isEscape = false;
		if ("key" in evt) {
			isEscape = (evt.key === "Escape" || evt.key === "Esc");
		} else {
			isEscape = (evt.keyCode === 27);
		}
		if (isEscape) {
			if (body.classList.contains('popup-open')) { 
				body.classList.remove('popup-open');
				//bigMenuOpen.reverse();
				enablePageScroll($scrollableElement);
				document.querySelector('.no-js').style.overflow = "auto";
			}
		}
	};

	// HEADER TINY ON SCROLL
	mm.add("(min-width: 992px)", () => {
		ScrollTrigger.create({
			start: "top -100px",
			//scroller: scrollerBox,
			end: 99999,
			//markers: true,

			toggleClass: { targets: 'header', className: '--tiny' },
		});
	});

	mm.add("(max-width: 991px)", () => {
		ScrollTrigger.create({
			start: "top -30px",
			//scroller: scrollerBox,
			end: 99999,
			//markers: true,

			toggleClass: { targets: 'header', className: '--tiny' },
		});
	});

	// HEADER REMOVE FIXED ON SCROLL
	/*
	ScrollTrigger.create({
		trigger: "footer",
		start: "bottom 80%",
		pin: true,
		//scroller: scrollerBox,
		//end: 99999,
		markers: true,

		toggleClass: { targets: 'header', className: '--free' },
	});
	*/

	// ACCORDION RESPONSIVE
	const colsContainer = document.querySelectorAll('.col-container');

	colsContainer.forEach(colContainer => {
		colContainer.querySelector('.accordion-btn').addEventListener('click', function() {
	
			if (colContainer.classList.contains('accordion-open')) { 
				//console.log('titi');
				gsap.to(window, {duration: 0, scrollTo: {y: colContainer, offsetY: 150}});
			}

			colContainer.classList.toggle('accordion-open');
				
		});
	});

	// SCROLL TO SERVICES
	const buttonsScrollServices = document.querySelectorAll('.scrollto-services');
	
	buttonsScrollServices.forEach(buttonScrollServices => {
		buttonScrollServices.addEventListener('click', function() {
			gsap.to(window, {duration: 2, scrollTo: {y: "#services-container", offsetY: 210}});
		});
	});

	// SERVICE LIST APPEAR
	const servicesContainer = document.querySelectorAll('.service-container');

	servicesContainer.forEach(serviceContainer => {
		serviceContainer.querySelector('.serviceBtnJS').addEventListener('click', function() {
			serviceContainer.classList.toggle('list-open');
		});
	});


	// SWIPER SERVICES RESPONSIVE
	var initSwiperServices = false;
	let swiperServices;

	mm.add("(max-width: 1399px)", () => {
		if (!initSwiperServices) {
			initSwiperServices = true;

			swiperServices = new Swiper('.services-container .swiper-container', {
				modules: [Navigation, Pagination, FreeMode],
				//loop: true,
				//grabCursor: true,
				//centeredSlides: true,
				spaceBetween: 12,
				//autoHeight: true,
				//freeMode: true,
				slidesPerView: 1,
				pagination: {
					el: ".swiper-pagination",
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				}, 
		
				breakpoints: {
					// when window width is >= 992px
					992: {
						slidesPerView: 2,
						spaceBetween: 36,
					}
				},
			});
		}
	});
		
	mm.add("(min-width: 1400px)", () => {
		if (initSwiperServices) {
			swiperServices.destroy();
			initSwiperServices = false;

			servicesContainer.forEach(serviceContainer => {
				serviceContainer.style.opacity = "1";
			});
		}
	});
	

	// SWIPER TESTIMONIALS
	const swiperTestimonials = new Swiper('.testimonials-container .swiper-container', {
		modules: [Navigation, Autoplay, FreeMode, EffectFade],
		loop: true,
		grabCursor: true,
		//centeredSlides: true,
		spaceBetween: 12,
		//freeMode: true,
		slidesPerView: 1,
		effect: 'fade',
        autoHeight: true,
        fadeEffect: {
          crossFade: true
        },
		autoplay: {
			delay: 5000,
			//disableOnInteraction: false,
		},
		navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        }, 

		breakpoints: {
			// when window width is >= 320px
			320: {
			  //slidesPerView: 2,
			  //spaceBetween: 20
			},
			// when window width is >= 768px
			768: {
				spaceBetween: 30,
			},
			// when window width is >= 992px
			992: {
				//centeredSlides: true,
				//freeMode: true,
				//slidesPerView: 1,
				//spaceBetween: 35,
			}
		},
	});
})


